<!--
 * @Description: 房源费用占比
 * @Author: 琢磨先生
 * @Date: 2022-06-28 02:49:48
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-04-22 10:20:21
-->

<template>
  <el-card class="box">
    <el-form :model="query" ref="query" :inline="true">
      <el-form-item label="房间">
        <el-cascader
          :options="huxing_list"
          v-model="query.storeIds"
          :props="props"
          clearable
          style="width: 300px"
          filterable
        ></el-cascader>
      </el-form-item>
      <el-form-item label="分成比例">
        <el-select v-model="query.income_rule_id" placeholder clearable>
          <el-option :label="item.name" :value="item.id" v-for="item in incomerules" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
      </el-form-item>
    </el-form>
    <div>
      <el-button
        type="primary"
        size="small"
        @click="onBatchSetting"
        round
        :disabled="selectionList.length <= 0"
        icon="setting"
        v-if="is_can_edit"
      >批量设置费用</el-button>
      <el-button
        type="success"
        size="small"
        @click="visibleIncomeDialog = true"
        round
        :disabled="selectionList.length <= 0"
        icon="setting"
        v-power="'seller/v1/incomeRule/batchSet'"
      >批量设置分成比例</el-button>
      <el-button
        type="danger"
        size="small"
        @click="onClearSelection"
        round
        :disabled="selectionList.length <= 0"
        icon="remove"
      >清除选择</el-button>
    </div>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table
      ref="refTable"
      :data="tableData.data"
      @sort-change="onSort"
      border
      row-key="id"
      @selection-change="selectionChange"
    >
      <el-table-column type="selection" width="55" reserve-selection />
      <el-table-column type="expand" label="展开" width="80">
        <template #default="scope">
          <el-card class="box">
            <el-row>
              <el-col :span="8" v-for="item in scope.row.group_expenses" :key="item.order_type">
                <el-descriptions :column="4" :title="item.type_text">
                  <el-descriptions-item
                    :label="item.item_name"
                    label-align="right"
                    v-for="item in item.expenses"
                    :key="item.id"
                  >{{ item.decimal_ratio }}%</el-descriptions-item>
                </el-descriptions>
              </el-col>
            </el-row>
          </el-card>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="id" label="编号" width="80"></el-table-column> -->
      <!-- <el-table-column label="房源数" width="120">
        <template #default="scope">
          <el-image :src="scope.row.house.logo_url" style="width: 80px; height: 60px" fit="cover"></el-image>
        </template>
      </el-table-column>-->
      <!-- <el-table-column prop="house.private_name" label="内部名称" width="180"></el-table-column> -->
      <!-- <el-table-column prop="house.name" label="房源标题"></el-table-column> -->
      <el-table-column prop="house_no" label="房号" width="200">
        <template #default="scope">
          <div>{{ scope.row.house_no }}</div>
          <div style="font-size:12px;color:#999">({{ scope.row.shop_name }})</div>
        </template>
      </el-table-column>
      <el-table-column label="房东" width="180">
        <template #default="scope">
          <div>{{ scope.row.landlord.name }}</div>
          <!-- <div>{{ scope.row.landlord.mobile_phone }}</div> -->
        </template>
      </el-table-column>
      <el-table-column label="分成比例">
        <template #default="scope">
          <div>{{ scope.row.income.name }}</div>
          <div>
            房东：{{ scope.row.income.decimal_income_rate }}%、商户：{{
            scope.row.income.business_income_rate
            }}%
          </div>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="120">
        <template #default="scope">
          <el-button type="primary" link @click="onSetting(scope.row)" v-if="is_can_edit">设置比例</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
      v-if="tableData.counts > 0"
    ></el-pagination>
  </el-card>

  <el-dialog
    title="设置业主费用支出占比"
    v-model="visibleDialog"
    width="1000px"
    top="10vh"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-alert title :closable="false" style="margin-bottom: 20px">
      <el-tag
        @close="selectionList.splice(i, 1)"
        closable
        v-for="(item, i) in selectionList"
        :key="item.id"
        style="margin: 0 10px 10px 0"
      >{{ item.house_no }}({{ item.landlord.name }})</el-tag>
    </el-alert>
    <el-form :model="form" ref="form" label-width="100px">
      <div v-for="pt in orderTypeList" :key="pt.order_type">
        <el-divider content-position="left">{{ pt.type_text }}</el-divider>
        <el-row>
          <el-col :span="7" v-for="item in pt.expenseList" :key="item.id">
            <el-form-item :label="item.name">
              <el-col :span="11">
                <el-input v-model="item.value" placeholder>
                  <template #suffix>%</template>
                </el-input>
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="11">
                <el-input v-model="item.decimal_unit_price" placeholder="单价">
                  <template #prefix>￥</template>
                </el-input>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>

    <template #footer>
      <div class="flex-row flex-between">
        <span style="color: #999; font-size: 14px">业主房东占比，比例为百分比，例如：50%则输入50，支持2位小数。不填写默认0</span>
        <el-button type="primary" @click="onSubmit" :loading="saving">确定</el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog
    title="分成比例设置"
    v-model="visibleIncomeDialog"
    width="1000px"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-alert title :closable="false" style="margin-bottom: 20px">
      <el-tag
        @close="selectionList.splice(i, 1)"
        closable
        v-for="(item, i) in selectionList"
        :key="item.id"
        style="margin: 0 10px 10px 0"
      >{{ item.house_no }}({{ item.landlord.name }})</el-tag>
    </el-alert>
    <el-form :model="incomeForm" ref="incomeForm" :rules="rules" label-width="100px">
      <el-form-item label="分成比例" prop="income_rule_id">
        <el-select v-model="incomeForm.income_rule_id" placeholder="请选择分成规则">
          <el-option
            :label="item.name + `(房东${item.decimal_income_rate}%)`"
            :value="item.id"
            v-for="item in incomerules"
            :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmitIncome" :loading="saving">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import expense_api from "@/http/expense_api";
import common_api from "@/http/common_api";
import huxing_api from "@/http/huxing_api";

export default {
  components: {},
  data() {
    return {
      loading: false,
      //显示弹框
      visibleDialog: false,
      //分成比例
      visibleIncomeDialog: false,
      saving: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
      orderTypeList: [
        {
          order_type: 0,
          type_text: "普通订单",
        },
        {
          order_type: 1,
          type_text: "试住订单",
        },
        {
          order_type: 2,
          type_text: "自主订单",
        },
      ],
      form: {},
      //选中的列表
      selectionList: [],
      //比例列表
      incomerules: [],
      //房型options
      huxing_list: [],
      props: {
        label: "name",
        value: "id",
      },
      incomeForm: {},
      //分成比例表单规则
      rules: {
        income_rule_id: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
      //可以编辑
      is_can_edit: false,
    };
  },
  created() {
    common_api.get_incomerules().then((res) => {
      if (res.code == 0) {
        this.incomerules = res.data;
      }
    });
    //获取所有的费用项
    expense_api.get_expenses().then((res) => {
      if (res.code == 0) {
        this.orderTypeList.forEach((item) => {
          item.expenseList = JSON.parse(JSON.stringify(res.data));
        });
      }
    });

    huxing_api.get_stores().then((res) => {
      if (res.code == 0) {
        this.huxing_list = [];
        res.data.forEach((x) => {
          var model = {
            id: x.id,
            name: x.name,
            children: [],
          };
          x.store_list.forEach((item) => {
            model.children.push({
              id: item.id,
              name: item.house_no,
            });
          });
          this.huxing_list.push(model);
        });
      }
    });

    this.is_can_edit = this.$power("seller/v1/expense/house-batch");

    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      if (this.query.storeIds) {
        this.query.store_id =
          this.query.storeIds[this.query.storeIds.length - 1];
      } else {
        this.query.store_id = "";
      }
      this.$http.post("seller/v1/expense/stores", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 多选
     * @param {*} item
     */
    selectionChange(values) {
      this.selectionList = values;
    },

    /**
     * 清除所有选择
     */
    onClearSelection() {
      this.selectionList = [];
      this.$refs.refTable.clearSelection();
    },

    /**
     * 单项设置
     */
    onSetting(item) {
      this.selectionList = [item];
      this.visibleDialog = true;
      this.handlerValue(item);
    },
    /**
     * 批量设置
     */
    onBatchSetting() {
      if (this.selectionList.length > 0) {
        this.visibleDialog = true;
        var item = this.selectionList[0];
        this.handlerValue(item);
      }
    },

    /**
     * 批量赋值
     */
    handlerValue(item) {
      this.orderTypeList.forEach((pt) => {
        pt.expenseList.forEach((x) => {
          if (item.expenses) {
            var val = item.expenses.find(
              (o) => o.order_type == pt.order_type && o.expense_item_id == x.id
            );
            if (val) {
              x.value = val.decimal_ratio;
              x.decimal_unit_price = val.decimal_unit_price;
            }
          } else {
            x.value = "";
            x.decimal_unit_price = "";
          }
        });
      });
    },

    /**
     * 提交
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.selectionList.length <= 0) {
            this.$message.info("请选择房源");
            return;
          }
          var values = [];
          this.orderTypeList.forEach((x) => {
            x.expenseList.forEach((item) => {
              console.log(item);
              values.push({
                expense_item_id: item.id,
                decimal_ratio: item.value ?? 0,
                order_type: x.order_type,
                decimal_unit_price: item.decimal_unit_price,
              });
            });
          });
          this.form.store_ids = this.selectionList.map((x) => x.id);
          this.form.expenses = values;
          this.saving = true;
          this.$http
            .post("/seller/v1/expense/house-batch", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.visibleDialog = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.onClearSelection();
                this.loadData();
              }
            })
            .cath(() => {
              this.loading = false;
            });
        }
      });
    },

    /**
     * 批量设置分成比例提交
     */
    onSubmitIncome() {
      this.$refs.incomeForm.validate((valid) => {
        if (valid) {
          if (this.selectionList.length <= 0) {
            this.$message.info("请选择房源");
            return;
          }
          this.incomeForm.storeIds = this.selectionList.map((x) => x.id);
          this.saving = true;
          this.$http
            .post("seller/v1/incomeRule/batchSet", this.incomeForm)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.visibleIncomeDialog = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.loadData();
              }
            })
            .cath(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
</style> 